import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalService } from '../../services/global/global.service';

@Component({
  selector: 'module-btns',
  templateUrl: './module-btns.component.html',
  styleUrls: ['./module-btns.component.scss']
})
export class ModuleBtnsComponent implements OnInit {
  apiParameters;
  apiLink;
  children;
  componentList;
  constructor(
              public router: Router,
              private globalVariables: GlobalService,
  ) { }

  ngOnInit() {
    this.globalVariables.setCompLoadedNum();
    console.log("test comp num in btn => ", this.globalVariables.compLoadedNum)
    console.log("children from add btn => ",this.children)
    console.log("componentsList in add Btn => ",this.componentList);


    // console.log("static api from btns => ",this.globalVariables.staticApi)
    console.log("apiLink ModuleBtnsComponent=> ", this.apiLink);
    console.log("apiParameters ModuleBtnsComponent=> ",this.apiParameters)
  }

  addItem(){
    //{ queryParams: { order: 'popular' } }
    //must send when go to the add page module id to define the module
    this.router.navigate(['/pages/layouts/layout-item-add'],{queryParams:{children:this.children,componentsList:JSON.stringify(this.componentList),staticApi:this.apiLink,apiParameters:JSON.stringify(this.apiParameters)}})
  }

}
