import { BASE_API_URL} from './../../../environments/environment';
import { Injectable, OnInit } from '@angular/core';
// import { TableComponent } from '../../shared/list/table/table.component';
import { EchartsPieComponent } from '../../pages/charts/echarts/echarts-pie.component';
import { EchartsLineComponent } from '../../pages/charts/echarts/echarts-line.component';
import { EchartsBarComponent } from '../../pages/charts/echarts/echarts-bar.component';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Http, RequestOptions,Headers } from '@angular/http';
import { ApiConstData } from '../../../consts/ApiConstData';
import { Observable } from 'rxjs';
import { TestMenuItems } from '../../test/static-structures/menu-items';

@Injectable({
  providedIn: 'root'
})
export class MenuService{
  blocks;
  layoutLink;
  apiBody;
  baseURL = BASE_API_URL;
  headers:HttpHeaders;
  constructor(private httpClient:HttpClient,
              private apiConstData:ApiConstData ,
              private testMenuItems:TestMenuItems,
    ) {
      
     }

 
  /**
   * this Fnction Try to get allitems in the side Menu
   * //xxxxxxxxxxxxxxxxxxx-3awza ashof tare2a 3'er de 3lshan yb2a el header w api body lw7dhom-xxxxxxxxxx
   */
  getMenuItem(langID){
    this.headers = this.apiConstData.getHeaderandBody().apiHeaders;
    this.apiBody = this.apiConstData.getHeaderandBody().apiBody;
    console.log("api body menu => ",this.apiBody)
    return this.httpClient.post<any>(`${this.baseURL}/menu`,this.apiBody,{headers:this.headers})
  }

     /**
      * (Testing) this function for testing when active this function please comment the main function (getMenuItem)
      * @param langID 
      */
    //  getMenuItem(langID) {
    //    this.headers = this.apiConstData.getHeaderandBody().apiHeaders;
    //    this.apiBody = this.apiConstData.getHeaderandBody().apiBody;
    //     let response = new Observable(observer=>{
    //       setTimeout(()=>{
    //         observer.next(this.testMenuItems.menuItems)
    //       },0);
    //     })
        
    //     return<any> response;
    //  }

   
    /**
     * API FUnction Called by Layout to get all components that will draw in it and name of the page  
     * @param pageID : Each page created by BE has PageID
     */
    getBlocksByPageID(pageID:number){
      if(pageID == 5){
        return this.blocks ={
          'name':'View List',
          'components':[EchartsBarComponent,EchartsLineComponent,EchartsPieComponent,]
        } 
      }
      if(pageID == 3){
        return this.blocks = []
      }
    }
    
    /**
     * this Function try to get the router link of layout to go to it 
     * @param id : layoutID 
     */
    getLayoutByID(id:number){
      console.log("layoutBYID from menu Service => ",id)
      
        if(id == 1){
          return this.layoutLink =  `/pages/layouts/layout1`
        }
        if(id == 2){
          return this.layoutLink =  `/pages/layouts/layout2`
        }
        if(id == 3){
          return this.layoutLink =  `/pages/layouts/layout-edit`
        }
        if(id == 4){
          return this.layoutLink =  `/pages/layouts/layout1`
        }
        if(id == 5){
          return this.layoutLink = `/pages/layouts/homepage`
        }
        if(id == 6) {
          return this.layoutLink = `/pages/layouts/layout_004`
        }
        if(id == 7){
          return this.layoutLink = `/pages/layouts/layout-item-add`
        }
        if(id == 8){
          return this.layoutLink = `/pages/layouts/layout_006`
        }
        if(id == 9){
          return this.layoutLink = `/pages/layouts/layout_003`
        }
        if(id == 10){
          return this.layoutLink = `/pages/layouts/layout-settings`
        }
        if(id == 11) {
          return this.layoutLink = `/pages/layouts/bills`
        }
        if(id==22) {
          return this.layoutLink = `/pages/static-pages/show`
        }
        if(id==23) {
          return this.layoutLink = `/pages/static-pages/permission`
        }
        if(id==24) {
          return this.layoutLink = `/pages/static-pages/template`
        }
        if(id==25) {
          return this.layoutLink = `/pages/static-pages/report-one`
        }
        
    }
  

  //menu Object
  menu = 
  {
    item:
      [
        {
          title: 'List',
          icon: 'nb-e-commerce',
          // link: '/pages/layouts/layout1',
          layout_id: 1,
          page_id: 5,
          module_id:'1',
          children:[]

        },
        {
          title: 'Cards',
          icon: 'nb-home',
          // link: '/pages/layouts/layout2',
          layout_id:2,
          page_id: 3,
          module_id:1,
          queryParams: { moduleID: '1'},

        },
        {
          title:'Settings',
          icon:'nb-gear',
          layout_id:6,
          page_id:3,
          module_id:'1',

        }
    ]
  }
     

  page = 
        [
        {
          "id": 1,
          "name": "list all products",
          "url": "http://penta-test.com/static_apis/form_generator/list",
          "type": "list",
          "layout": 1
        },
        {
          "id": 1,
          "name": "view product",
          "url": "http://penta-test.com/static_apis/form_generator/view/{id}",
          "type": "view",
          "layout": 1
        }
      ]
    

 
}
