import { BASE_API_URL} from './../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConstData } from '../../../consts/ApiConstData';

@Injectable({
  providedIn: 'root'
})
export class FormService {
  baseURL = "";
  headers;
  apiConstBody;
  
  constructor(private httpClient:HttpClient,
              public apiConstData: ApiConstData,
    ) {
      this.headers = apiConstData.getHeaderandBody().apiHeaders;
      this.apiConstBody =  apiConstData.getHeaderandBody().apiBody;
    console.log("constructor of form")
    }


  /**
   * this function try to Fetch data to draw Add form 
   * @param moduleID the module ID to know which fields draw in the Form
   */
  createCmsAddForm(moduleID,api){
    console.log("headers in create => ",this.headers)
    let specificBody ={
      "module_id" : moduleID,
    }
    let body = {...specificBody,...this.apiConstData.getHeaderandBody().apiBody}
    console.log("body of create form => ",body)

    return this.httpClient.post<any>(`${this.baseURL}${api}/create`,body,{headers:this.headers})
  }

  /**
   * this function try to draw Edit form so the response that returned frm this function it will contain the data to fill the fields
   * @param itemID that item you want to make editing on it 
   */
  createCmsEditForm(itemID,api){
    console.log("itemID form => ",itemID)

    let specificBody={
        "id" : itemID,
    }
    let body = {...specificBody,...this.apiConstData.getHeaderandBody().apiBody}
    console.log("body of create form => ",body)
    return this.httpClient.post<any>(`${this.baseURL}${api}/edit`,body,{headers:this.headers})
  }

  updateitem(formValue,apiRef){
    console.log("apiRef =>",apiRef)
    formValue = {...formValue,...this.apiConstData.getHeaderandBody().apiBody}

    console.log("update form Value=> ", formValue)
    return this.httpClient.post<any>(`${this.baseURL}${apiRef}/update`,formValue,{headers:this.headers})
  }

  submitAddForm(formValue,apiref){
    console.log("da5al submit Add frm")
    console.log("formValue before=> ",formValue)
   
    formValue = {...formValue,...this.apiConstData.getHeaderandBody().apiBody}
    console.log("constBody => ",this.apiConstData.getHeaderandBody().apiBody)
    console.log("Submit form Value =>" ,formValue)
    
    return this.httpClient.post<any>(`${this.baseURL}${apiref}/store`,formValue,{headers:this.headers})
  }

  // form2_create(){
  //   return this.httpClient.post<any>(`${this.baseURL}${apiref}/`,)
  // }
}


