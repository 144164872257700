import { HttpClient } from '@angular/common/http';
import { Gp__002_004 } from './../../../shared/components/_#002__forms/_comp__#002_#004__gp/gp__002_004.component';
import { Tabs_basic__002_002 } from './../../../shared/components/_#002__forms/_comp__#002_#002__tabs_basic/tabs_basic__002_002.component';
import { FormService } from './../../../services/form/form.service';
import { Component, OnInit, ViewChild, ViewContainerRef, ComponentFactoryResolver } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray, FormControl } from '@angular/forms';
import { text, elementEnd } from '@angular/core/src/render3';

import { CmsModuleService } from './../../../services/cms-module/cms-module.service';
import { GlobalService } from '../../../services/global/global.service';
import { Select2OptionData } from 'ng2-select2';
import { GlobalFunctions } from '../../../../consts/global-functions';
import { ActivatedRoute, Router } from '@angular/router';
import { TestServiceService } from '../../../services/test_service/test-service.service';
import { ApiConstData } from '../../../../consts/ApiConstData';
import { Configurations } from '../../../../consts/configurations';
import { Nav_pill_add__002_005 } from '../../../shared/components/_#002__forms/_comp__#002_#005__nav_pill_add/nav_pill_add__002_005.component';



@Component({
  selector: 'ngx-layout-item-add',
  templateUrl: './layout-item-add.component.html',
  styleUrls: ['./layout-item-add.component.scss']
})
export class LayoutItemAddComponent implements OnInit {
  @ViewChild('form', { read: ViewContainerRef }) form: ViewContainerRef
  staticApi: string;
  moduleID: string;
  formStructure: Object;
  loading: boolean = false;
  loadingConfig: any;
  children;
  filter_ref:Boolean;
  componentsList = []


  apiParameters;

  constructor(public formBuilder: FormBuilder,
    private formService: FormService,
    private activatedRoute: ActivatedRoute,
    private testService: TestServiceService,
    private resolver: ComponentFactoryResolver,
    private httpClient: HttpClient,
    private apiConst: ApiConstData,
    public config: Configurations,
    private globalService: GlobalService,
    private router: Router
  ) {

    // this.activatedRoute.queryParams.subscribe(params => {
    //   console.log("params item_add_layout => ",params);

    //   this.staticApi = params.staticApi;
    //   this.apiParameters = params.apiParameters;
    //   if( this.staticApi == undefined) {
    //     this.componentsList = JSON.parse(params.componentsList)
    //     this.staticApi =this.componentsList[0].api;
    //     console.log("api form undefine=> ",this.staticApi)
    //   }
    //   if(this.apiParameters == undefined) {
    //     this.componentsList = JSON.parse(params.componentsList);
    //     console.log("compo k=> ", this.componentsList)
    //     this.apiParameters =  this.componentsList[0].parameters;
    //     console.log("api from undefind api parameters=> ",this.apiParameters)
    //   }
    //   this.moduleID = params.moduleID;
    //   // this.apiParameters = JSON.parse(params.apiParameters)
    //   console.log("static APi from query params => ", this.staticApi);
    //   console.log("api parameters from query params => ", this.apiParameters);

    // });

    let sessionParams = JSON.parse(sessionStorage.getItem("queryParams"));
    this.children = JSON.parse(sessionParams['children']);
    this.componentsList = JSON.parse(sessionParams['componentsList']);
    console.log("components list in layout add item => ", this.componentsList);

    let current_item = this.componentsList.find(function (element) { if (element.name == 'createBtn') return element });
    if (current_item == undefined) {
      this.staticApi = this.componentsList[0].api;
      this.apiParameters = this.componentsList[0].parameters;
    }
    else {
      console.log("current_item=> ", current_item)
      this.staticApi = current_item.api;
      this.apiParameters = current_item.parameters;
    }
    this.moduleID = sessionParams['moduleID'];
    this.globalService.moduleID = this.moduleID;


  }
  ngOnInit() {
    console.log("layoutItem Add"),
    console.log("staticAPI add=> ",this.staticApi);
    console.log("apiParameter add ",this.apiParameters);
    console.log("filter_ref",this.filter_ref);
    this.loading = true;
    this.loadingConfig = this.config.loadingConfig;

    this.httpClient.post<any>(`${this.staticApi}`, this.apiParameters, { headers: this.apiConst.getHeaderandBody().apiHeaders }).subscribe(response => {
      this.loading = false;
      if(response.status == 401){
        localStorage.clear();
        this.router.navigate(['./login'])
      }

    else {
      this.formStructure = response.content;
      console.log("responserr => ", response)
      console.log("response of add item => ", response.content);
      const factory = this.resolver.resolveComponentFactory(Nav_pill_add__002_005);
      const cmponentRef = this.form.createComponent(factory);
      // cmponentRef.instance.formStructure = this.testService.addFieldWithAppend.content;
      cmponentRef.instance.formStructure = response.content;
      cmponentRef.instance.filter_ref = this.filter_ref;
    }
    },
      error => {
        this.loading = false;
      }
    )

    //=======================-for testing-==================
    // const factory = this.resolver.resolveComponentFactory(Tabs_basic__002_002);
    // const cmponentRef = this.form.createComponent(factory);
    // cmponentRef.instance.formStructure = this.testService.addFormFields.content
  }


}
